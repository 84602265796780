import axios from "axios";
import Cookie from "js-cookie";

// Base URL for the API
const BASE_URL = "https://swipetoshare.com/api/";

// API endpoints
export const ENDPOINTS = {

  getPermissions: "/permissions/list",
  createPermission: "/permissions/create",
  getpermissionsbyid: "/getpermissionsbyid",
  updatePermission: "/permissions/update",
  deletePermission: "/permissions/delete",

  //login
  login: "/login",
  loginAsSupplier: "/suppliers/login-as-suppliers",
  stopImpersonation: "suppliers/stop-impersonation",
  emailverificationCode: "send-code",
  codeVerify: "verify-code",
  register: "register",

  //roles
  getRoles: "/roles/list",
  createRoles: "/roles/create",
  updateRoles: "/roles/update",
  getRolesByID: "/getRolesByID",
  deleteRoles: "/roles/delete",

  //brands
  getBrands: "/brand/list",
  createBrands: "/brand/create",
  updateBrands: "/brand/update",
  getBrandsByID: "/getBrandsByID",
  deleteBrands: "/brand/delete",

  //Category
  getCategory: "/categories/list",
  createCategory: "/categories/create",
  updateCategory: "/categories/update",
  getCategoryByID: "/getCategoryByID",
  deleteCategory: "/categories/delete",
  updateCategoryStatusByID: "/updateCategoryStatusByID",

  //State
  getState: "/state/list",
  createState: "/state/create",
  updateState: "/state/update",
  getStateByID: "/getStateByID",
  deleteState: "/state/delete",
  getStateBycountryID: "getStateBycountryID",

  //City
  getCity: "/city/list",
  createCity: "/city/create",
  updateCity: "/city/update",
  getCityID: "/getCityID",
  deleteCity: "/city/delete",

  //Suppliers
  getSupplierByStatus: "/getSupplierByStatus",
  addSupplier: "suppliers/create",
  deleteSupplier: "suppliers/delete",
  getSupplierById: "getSupplierID",
  updateSupplierDetail: "suppliers/update",
  addAdminSupplierDeail: "admin/suppliers/create",
  updateAdminSupplierDetail: "admin/suppliers/update",

  //SuperAdmin
  getSuperAdminList: "superadmin/list",
  getSuperAdminById: "getSuperadminID",
  deleteSuperAdmin: "superadmin/delete",
  addSuperAdmin: "superadmin/create",
  updateSuperAdminDetail: "superadmin/update",

  //Affiliate
  getAffiliateList: "affiliate/list",
  getAffiliateById: "getAffiliateID",
  deleteAffiliate: "affiliate/delete",
  addAffiliate: "affiliate/create",
  updateAffiliate: "affiliate/update",

  //Ticket-Category
  getTicketCategory: "ticket-categories/list",
  deleteTicketCategory: "ticket-categories/delete",
  getTicketCategoryByID: "getTicketCategoryByID",
  addTicketCategory: "ticket-categories/create",
  updateTicketCategory: "ticket-categories/update",

  //Ticket-Priority
  getTicketPriority: "ticket-priorities/list",
  deleteTicketPriority: "ticket-priorities/delete",
  getTicketPriorityByID: "getTicketPriorityByID",
  addTicketPriority: "ticket-priorities/create",
  updateTicketPriority: "ticket-priorities/update",

  //marketplace
  getMarketplace: "/marketplace/list",
  getMarketplaceByID: "/getMarketplaceID",
  createMarketplace: "/marketplace/create",
  updateMarketplace: "/marketplace/update",
  deleteMarketplace: "/marketplace/delete",

  //shippingcarriers
  getShippingCarriers: "/shipping-carriers/list",
  createShippingCarriers: "/shipping-carriers/create",
  updateShippingCarriers: "/shipping-carriers/update",
  deleteShippingCarriers: "/shipping-carriers/delete",
  getShippingCarriersByID: "/getShippingCarriersByID",

  //shippingcarriers
  getShippingServices: "/shipping-services/list",
  createShippingServices: "/shipping-services/create",
  updateShippingServices: "/shipping-services/update",
  deleteShippingServices: "/shipping-services/delete",
  getShippingServiceByID: "/getShippingServiceByID",
  //Country
  getCountry: "country/list",

  //Fulfillment
  getFulfillment: "/fulfillment-users/list",
  createFulfillment: "/fulfillment-users/create",
  updateFulfillment: "/fulfillment-users/update",
  getFulfillmentID: "/getFulfillmentID",
  deleteFulfillment: "/fulfillment-users/delete",

  //product
  getProducts: "/product/list",
  createProducts: "/product/create",
  updateProducts: "/product/update",
  deleteProducts: "/product/delete",
  getProductID: "/getProductID",

  //Master
  getAllCity: "getAllCity",
  getAllState: "getAllState",
  getAllSuppliers: "getAllSuppliers",
  getAllBrands: "getAllBrands",
  getAllProductStatus: "getAllProductStatus",
  getAllCountry: "getAllcountry",
  getAllMarketplace: "getAllMarketplace",
  getAllMainCategories: 'getAllMainCategories',
  getAllWarehouse: 'getAllWarehouse',


  //Warehouses
  getWarehouses: "warehouse/list",



  //Supplier Panel Api Call

  //Product
  getSuppliersProductList: "suppliers/product-list",
  supplierProductUpload: "suppliers/upload-products",
  supplierImageUpload: "suppliers/upload-image",
  downloadProductFile: "suppliers/downlaod-product",
  getAllImages: "suppliers/get-all-images",
  getAllProductFileList: "suppliers/product-files-list",
  getAllProductExcel: "suppliers/download-suppliers-products",
  deleteImage: "suppliers/delete-image",


  //WareHouse 
  getWareHouseList: 'warehouse/list',
  createWareHouse: 'warehouse/create',
  deleteWareHouse: 'warehouse/delete',
  getWarehouseID: 'getWarehouseID',
  updateWarehouseDetail: 'warehouse/update',


  //warehouse Inventory
  getWareHouseInventory: 'warehouse-inventory/list',
  getWareHouseInventoryListById: 'warehouse-inventory/GetInventoryListById',
  downloadWarehouseInventory: 'warehouse-inventory/downlaod-warehouse-inventory',
  uploadWareHouseInventory: 'warehouse-inventory/upload-warehouse-inventory',
  deleteWareHouseInventory: 'warehouse-inventory/delete',
  UpdateInventoryQuantityById: 'warehouse-inventory/UpdateInventoryQuantityById',
};

// Create an Axios instance
const apiInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});
apiInstance.interceptors.request.use(
  (config) => {
    const token = Cookie.get("userToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default apiInstance;
