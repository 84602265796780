import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import Footer from "../Footer";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import apiInstance, { ENDPOINTS } from "../../Api";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography, Box, InputAdornment,
  Button
} from "@mui/material";
import { useLoading } from "../../context/LoadingContext";
import { openTermsInPopup } from "../../constant/constant";
function RegisterSupplier() {
  const [countryList, setCountryList] = useState([]);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleTermsConditionCheckbox = (event) => {
    setChecked(event.target.checked);
  };


  // Close the document to finish rendering

  const { loading, setLoading } = useLoading();
  const { id } = useParams();
  const navigate = useNavigate();
  const [addUser, setAddUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    website_url: "",
    commercial_company_name: "",
    shop_name: "",
    country_of_registration: "",
    business_registration_number: "",
    tax_id_number: "",
    is_selling_on_marketplaces: "no",
    status: "pending",
    marketplace_list: [],
    marketplace_integration: [],
    number_of_marketplace_products: "",
    main_categories: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddUser({
      ...addUser,
      [name]: value,
    });
  };

  const fetchMarketplace = async () => {
    try {
      const response = await apiInstance.post(ENDPOINTS.getAllMarketplace);
      if (response.data.success) {
        setMarketplaceList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching Marketplace");
    }
  };
  const fetchCountry = async () => {
    try {
      const response = await apiInstance.post(ENDPOINTS.getAllCountry);
      if (response.data.success) {
        setCountryList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching Country");
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await apiInstance.post(ENDPOINTS.getAllMainCategories);
      if (response.data.success) {
        setCategoryList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error fetching getAllMainCategories");
    }
  };

  // const fetchSupplierDetail = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await apiInstance.post(ENDPOINTS.getSupplierById, {
  //       id: id,
  //     });
  //     if (response.data.success) {
  //       let supplierDetail = response.data.data;
  //       setAddUser({
  //         ...addUser,
  //         email: supplierDetail.email,
  //         phone_number: supplierDetail.phone_number,
  //         status: supplierDetail.status,
  //         first_name: supplierDetail.first_name,
  //         last_name: supplierDetail.last_name,
  //         website_url: supplierDetail.website_url,
  //         commercial_company_name: supplierDetail.commercial_company_name,
  //         shop_name: supplierDetail.shop_name,
  //         country_of_registration: supplierDetail.country_of_registration,
  //         business_registration_number:
  //           supplierDetail.business_registration_number,
  //         tax_id_number: supplierDetail.tax_id_number,
  //         is_selling_on_marketplaces: supplierDetail.is_selling_on_marketplaces,
  //         marketplace_list: marketplaceList.filter((item) =>
  //           supplierDetail.marketplace_list.includes(item.id)
  //         ),
  //         marketplace_integration: supplierDetail.marketplace_integration,
  //         number_of_marketplace_products:
  //           supplierDetail.number_of_marketplace_products,
  //         main_categories: categoryList.filter((item) =>
  //           supplierDetail.main_categories.includes(item.id)
  //         ),
  //       });
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //     setLoading(false)
  //   } catch (error) {
  //     toast.error("Error fetching Suppliers Detail By Id");
  //   }
  // };
  useEffect(() => {
    fetchCategory();
    fetchMarketplace();
    if (!id) {
      setAddUser({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        website_url: "",
        commercial_company_name: "",
        shop_name: "",
        country_of_registration: "",
        business_registration_number: "",
        tax_id_number: "",
        is_selling_on_marketplaces: "no",
        status: "pending",
        marketplace_list: [],
        marketplace_integration: [],
        number_of_marketplace_products: "",
        main_categories: [],
      });
    }
    document.title = "SellNorm | UsersAdd";

    fetchCountry();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form before submission
    setLoading(true);
    let formData = addUser;

    addUser["main_categories"] = [
      ...new Set(addUser?.main_categories.map((item) => item?.id)),
    ];
    addUser["marketplace_list"] = [
      ...new Set(addUser?.marketplace_list.map((item) => item?.id)),
    ];
    console.log(formData);
    // If validation passes, create form data object to send (for file handling)

    try {
      const response = await apiInstance.post(
        ENDPOINTS.addSupplier,
        formData
      );
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/login");
      } else {
        toast.error(response.data.message);
        setAddUser({
          ...addUser,
          marketplace_list: [],
          main_categories: [],
        })
      }
      setLoading(false);

    } catch (error) {
      setAddUser({
        ...addUser,
        marketplace_list: [],
        main_categories: [],
      })
      setLoading(false);
      toast.error("Error Add Suppliers");
    }

  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setAddUser((prev) => ({
      ...prev,
      marketplace_integration: checked
        ? [...prev.marketplace_integration, value]
        : prev.marketplace_integration.filter((item) => item !== value),
    }));
  };
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8" style={{ margin: "0 auto" }}>
          <section className="ant-layout ant-layout-has-sider css-1i5zyo0" id="supplierresgiter">
            <section
              className="ant-layout css-1i5zyo0">
              <div className="css-14vsxfy mt-0 pt-4">
                <form onSubmit={handleSubmit}>
                  <main className="ant-layout-content h-100 css-1i5zyo0">
                    <div
                      className="page-header-alt border-bottom css-qquq3l"
                      style={{ backgroundImage: "none" }}
                    >
                      <div className="container">
                        <div className="css-1pvwqc2">
                          <img height="60" width="60" src="/static/media/logo.0bdc3bf222e1bd525ff9.jpg" alt="SellNorm logo" />
                          <h3 className="mb-0 mr-3 font-weight-semibold">
                            Register New Supplier
                          </h3>
                          <nav className="ant-breadcrumb css-1i5zyo0">
                            <ol>
                              <li>
                                <span className="ant-breadcrumb-link">
                                  <a href="/app">Home</a>
                                </span>
                              </li>
                              <li
                                className="ant-breadcrumb-separator"
                                aria-hidden="true"
                              >
                                /
                              </li>
                              <li>
                                <span className="ant-breadcrumb-link">
                                  <NavLink to="/users">Users</NavLink>
                                </span>
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>

                    <div
                      id="advanced_search"
                      className="ant-form ant-form-vertical css-1i5zyo0 ant-advanced-search-form"
                    >
                      <div className="container">
                        <div
                          className="ant-tabs ant-tabs-top css-1i5zyo0"
                          style={{ marginTop: "30px" }}
                        >
                          <div className="ant-tabs-content-holder">
                            <div className="ant-tabs-content ant-tabs-content-top">
                              <div
                                role="tabpanel"
                                tabIndex="0"
                                aria-hidden="false"
                                className="ant-tabs-tabpane ant-tabs-tabpane-active"
                                id="rc-tabs-0-panel-1"
                                aria-labelledby="rc-tabs-0-tab-1"
                              >
                                <div
                                  className="ant-row css-1i5zyo0"
                                  style={{
                                    marginLeft: "-8px",
                                    marginRight: "-8px",
                                  }}
                                >
                                  <div
                                    className="col-12 css-1i5zyo0"
                                    style={{
                                      paddingLeft: "8px",
                                      paddingRight: "8px",
                                    }}
                                  >
                                    <Typography mb={2} variant="h5" gutterBottom>
                                      Supplier Information
                                    </Typography>
                                    <div className="ant-card ant-card-bordered css-1i5zyo0">
                                      <form>
                                        <div className="ant-card-body">
                                          <div className="form-row ant-form-item css-1i5zyo0">
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Name"
                                              >
                                                First Name
                                              </label>
                                              <input
                                                type="text"
                                                name="first_name"
                                                value={addUser.first_name}
                                                onChange={handleInputChange}
                                                placeholder="First Name"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Name"
                                              >
                                                Last Name
                                              </label>
                                              <input
                                                type="text"
                                                name="last_name"
                                                value={addUser.last_name}
                                                onChange={handleInputChange}
                                                placeholder="Last Name"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Email"
                                              >
                                                Email
                                              </label>
                                              <input
                                                type="email"
                                                name="email"
                                                value={addUser.email}
                                                onChange={handleInputChange}
                                                placeholder="Email"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Phone Number"
                                              >
                                                Phone Number
                                              </label>
                                              <input
                                                type="number"
                                                name="phone_number"
                                                value={addUser.phone_number}
                                                onChange={handleInputChange}
                                                placeholder="Phone Number"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Website_url"
                                              >
                                                Website Url
                                              </label>
                                              <input
                                                type="text"
                                                name="website_url"
                                                value={addUser.website_url}
                                                onChange={handleInputChange}
                                                placeholder="Website URL"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-12 ant-form-item-label css-1i5zyo0">
                                              <Typography
                                                mb={2}
                                                mt={2}
                                                variant="h5"
                                                gutterBottom
                                              >
                                                Company Information
                                              </Typography>
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Commercial Company Name"
                                              >
                                                Commercial Company Name
                                              </label>
                                              <input
                                                type="text"
                                                name="commercial_company_name"
                                                value={
                                                  addUser.commercial_company_name
                                                }
                                                onChange={handleInputChange}
                                                placeholder="Commercial Company Name"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Shop Name"
                                              >
                                                Shop Name
                                              </label>
                                              <input
                                                type="text"
                                                name="shop_name"
                                                value={addUser.shop_name}
                                                onChange={handleInputChange}
                                                placeholder="Shop Name"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Country of Registration"
                                              >
                                                Country of Registration
                                              </label>
                                              <select
                                                id="advanced_search_dropdown"
                                                name="country_of_registration"
                                                value={
                                                  addUser.country_of_registration
                                                }
                                                onChange={(e) => {
                                                  setAddUser({
                                                    ...addUser,
                                                    country_of_registration:
                                                      e.target.value,
                                                  });
                                                }}
                                                className="ant-input css-1i5zyo0"
                                              >
                                                <option value="" disabled>
                                                  Select a Country of
                                                  Registration
                                                </option>
                                                {countryList.map(
                                                  (country) => (
                                                    <option
                                                      key={country.id}
                                                      value={country.id}
                                                    >
                                                      {country.name}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Business Registration Number"
                                              >
                                                Business Registration Number
                                              </label>
                                              <Tooltip
                                                title="Enter your Business Registration Number (e.g. 123456789)"
                                                arrow
                                              >
                                                <input
                                                  type="text"
                                                  name="business_registration_number"
                                                  value={
                                                    addUser.business_registration_number
                                                  }
                                                  onChange={handleInputChange}
                                                  placeholder="Business Registration Number"
                                                  aria-required="true"
                                                  className="ant-input css-1i5zyo0"
                                                />
                                              </Tooltip>
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Tax Id Number"
                                              >
                                                Tax Id Number
                                              </label>
                                              <Tooltip
                                                title="Enter your Tax Id Number (e.g. AAAAA9999A)"
                                                arrow
                                              >
                                                <input
                                                  type="text"
                                                  name="tax_id_number"
                                                  value={
                                                    addUser.tax_id_number
                                                  }
                                                  onChange={handleInputChange}
                                                  placeholder="Tax Id Number"
                                                  aria-required="true"
                                                  className="ant-input css-1i5zyo0"
                                                />
                                              </Tooltip>
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Tax Id Number"
                                              >
                                                Is selling on marketplaces
                                              </label>
                                              <br />
                                              <Switch
                                                checked={
                                                  addUser.is_selling_on_marketplaces ===
                                                    "yes"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  setAddUser({
                                                    ...addUser,
                                                    is_selling_on_marketplaces:
                                                      e.target.checked
                                                        ? "yes"
                                                        : "no",
                                                    marketplace_list: [],
                                                  });
                                                }}
                                                inputProps={{
                                                  "aria-label": "controlled",
                                                }}
                                              />
                                            </div>
                                            {addUser.is_selling_on_marketplaces ===
                                              "yes" ? (
                                              <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                <label
                                                  htmlFor="advanced_search_name"
                                                  className="ant-form-item-required"
                                                  title="MarketPlace"
                                                >
                                                  MarketPlace
                                                </label>
                                                <Autocomplete
                                                  filterSelectedOptions
                                                  options={marketplaceList}
                                                  value={
                                                    addUser?.marketplace_list
                                                  }
                                                  onChange={(e, value) => {
                                                    setAddUser({
                                                      ...addUser,
                                                      marketplace_list: value,
                                                    });
                                                  }}
                                                  getOptionLabel={(option) =>
                                                    option?.name
                                                  }
                                                  multiple
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label="Add Marketplace"
                                                      className="dialogue_input_fields"
                                                    />
                                                  )}
                                                />
                                              </div>
                                            ) : null}
                                            <div className="form-group col-md-12 ant-form-item-label css-1i5zyo0" id="marketPlaceintegration">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="MarketPlace Integration"
                                              >
                                                MarketPlace Integration
                                              </label>
                                              <FormGroup>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      value="Manual"
                                                      checked={addUser.marketplace_integration.includes(
                                                        "Manual"
                                                      )}
                                                      onChange={
                                                        handleCheckboxChange
                                                      }
                                                    />
                                                  }
                                                  label="Manual"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      value="API"
                                                      checked={addUser.marketplace_integration.includes(
                                                        "API"
                                                      )}
                                                      onChange={
                                                        handleCheckboxChange
                                                      }
                                                    />
                                                  }
                                                  label="API"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      value="Feed Manager"
                                                      checked={addUser.marketplace_integration.includes(
                                                        "Feed Manager"
                                                      )}
                                                      onChange={
                                                        handleCheckboxChange
                                                      }
                                                    />
                                                  }
                                                  label="Feed Manager"
                                                />
                                              </FormGroup>
                                            </div>
                                            <div className="form-group col-md-12 ant-form-item-label css-1i5zyo0">
                                              <Typography
                                                mb={2}
                                                mt={1}
                                                variant="h5"
                                                gutterBottom
                                              >
                                                Catalog Information
                                              </Typography>
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Number of marketplace products"
                                              >
                                                Number of marketplace products
                                              </label>
                                              <input
                                                type="number"
                                                name="number_of_marketplace_products"
                                                value={
                                                  addUser.number_of_marketplace_products
                                                }
                                                onChange={handleInputChange}
                                                placeholder="Number of Marketplace Products"
                                                aria-required="true"
                                                className="ant-input css-1i5zyo0"
                                              />
                                            </div>
                                            <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                              <label
                                                htmlFor="advanced_search_name"
                                                className="ant-form-item-required"
                                                title="Main Category"
                                              >
                                                Main Category
                                              </label>
                                              <Autocomplete
                                                filterSelectedOptions
                                                options={categoryList}
                                                value={
                                                  addUser?.main_categories
                                                }
                                                onChange={(e, value) => {
                                                  setAddUser({
                                                    ...addUser,
                                                    main_categories: value,
                                                  });
                                                }}
                                                getOptionLabel={(option) =>
                                                  option?.name
                                                }
                                                multiple
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Add Main Category"
                                                    className="dialogue_input_fields"
                                                  />
                                                )}
                                              />
                                            </div>
                                            <div className="form-group col-md-12 ant-form-item-label css-1i5zyo0">
                                              <Typography variant="body1">
                                                By register up, you agree to the{' '}
                                                <span
                                                  onClick={openTermsInPopup}
                                                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                  Terms and Conditions
                                                </span>.
                                              </Typography>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={checked}
                                                    onChange={handleTermsConditionCheckbox}
                                                    color="primary"
                                                  />
                                                }
                                                label="I agree to the Terms and Conditions"
                                              />
                                            </div>
                                            <div className="form-group col-md-12 ant-form-item-label css-1i5zyo0">
                                              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!checked}>
                                                Submit
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </form>
              </div>
              <Footer />
            </section>
          </section>
        </div>
      </div>
    </>
  );
}

export default RegisterSupplier;