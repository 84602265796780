import { useEffect } from "react"

import * as $ from 'jquery'
import { openTermsInPopup } from "../constant/constant";

export default function Footer() {

  useEffect(() => {
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('#imagePreview').css('background-image', 'url(' + e.target.result + ')');
          $('#imagePreview').hide();
          $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imageUpload").change(function () {
      readURL(this);
    });
    // Add More Form 

    $('.extra-fields-customer').click(function () {
      $('.customer_records').clone().appendTo('.customer_records_dynamic');
      $('.customer_records_dynamic .customer_records').addClass('single remove');
      $('.single .extra-fields-customer').remove();
      $('.single').append('<a href="#" class="remove-field btn-remove-customer">Remove Fields</a>');
      $('.customer_records_dynamic > .single').attr("class", "remove");

      $('.customer_records_dynamic input').each(function () {
        var count = 0;
        var fieldname = $(this).attr("name");
        $(this).attr('name', fieldname + count);
        count++;
      });

    });

    $(document).on('click', '.remove-field', function (e) {
      $(this).parent('.remove').remove();
      e.preventDefault();
    });


    $("#menu-close-sidebar").click(function () {
      $("#sidebar-blocks").addClass("sidebar-blocksblue");
      $("#main-block-forpage").addClass("removepadding-page");
      //$("div").addClass("important");
      $("#menu-open-sidebar").show();
      $("#menu-close-sidebar").hide();
    });

    $("#menu-open-sidebar").click(function () {
      $("#sidebar-blocks").removeClass("sidebar-blocksblue");
      $("#main-block-forpage").removeClass("removepadding-page");
      //$("div").addClass("important");
      $("#menu-open-sidebar").hide();
      $("#menu-close-sidebar").show();
    });


  }, [])

  return (
    <>
      <footer className="css-1vsxyv9">
        <span>Copyright  ©  2024 <span className="font-weight-semibold">SellNorm</span> All rights reserved.</span>
        <div><a className="text-gray" onClick={openTermsInPopup}>Term &amp; Conditions</a><span className="mx-2 text-muted"> | </span><a className="text-gray" href="/#">Privacy &amp; Policy</a></div>
      </footer>
    </>
  )
}