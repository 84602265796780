export const openTermsInPopup = () => {
    const popup = window.open(
        '',
        'Terms and Conditions',
        'width=600,height=400,resizable,scrollbars'
    );

    // Write content directly to the popup window
    popup.document.write(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>SellNorm Supplier Program – Terms and Conditions</title>
</head>
<body>
    <h1>SellNorm Supplier Program – Terms and Conditions</h1>
    
    <h2>1. Term of Agreement:</h2>
    <ul>
        <li>-	This Agreement shall be effective on the date of the acceptance of the terms and conditions of this Agreement (hereinafter referred to as the “Effective Date”) and will end on one calendar year afterwards.</li>
        <li>-	Upon the expiration of the term of the Agreement, this Agreement will be automatically renewed for additional terms of one year.</li>
    </ul>
    
    <h2>2. Termination:</h2>
    <ul>
        <li>This Agreement may be terminated in case the following occurs:</li>
        <ul>
            <li>a) Immediately in case one of the Parties breaches this Agreement.</li>
            <li>b) If the Supplier products do not generate sufficient sales during the term of this Agreement.</li>
            <li>c) At any given time by providing a written notice to the other party thirty (30) days prior to terminating the Agreement. All product listings will be removed from SELLNORM’S platforms within ninety (90) days from such termination.</li>
        </ul>
    </ul>
    
    <h2>3. Relationship between the parties:</h2>
    <ul>
        <li>The Parties agree that the Supplier in this Agreement is an independent contractor where the Supplier provides the services hereunder and acts as an independent contractor.</li>
        <li>Under no circumstances shall the Supplier be considered an employee.</li>
        <li>This Agreement does not create any other partnership between the Parties.</li>
    </ul>
    
    <h2>4. Insurance:</h2>
    <ul>
        <li>Supplier shall at its own expense, secure and maintain throughout the term of these Terms and Conditions, the following insurance coverage:</li>
        <ul>
            <li>-	Commercial General Liability Insurance with a minimum combined single limit of liability of One Million Dollars ($2,000,000) per occurrence and Two Million Dollars ($2,000,000) aggregate for bodily injury and/or death and/or property damage and/or personal injury. This shall include products/completed operations coverage and shall also include Broad Form Contractual coverage specifically for the Terms and Conditions; and </li>
            <li>-	Cyber liability insurance in the minimum amount of One Million Dollars ($1,000,000). </li>
            <li>-	Supplier shall name SellNorm as an additional insured and provide copies of the above-mentioned insurances to SellNorm within fifteen (15) days from the acceptance of the Terms and Conditions.</li>
        </ul>
    </ul>
    
    <h2>5. Supplier declarations, warranties and representations:</h2>
    <ul>
        <li>Supplier hereby confirms that it operates its business in conformity with all Applicable Laws and regulations.</li>
        <li>Supplier has obtained all licenses, permits, regulatory approvals, registrations, and authorizations necessary to enter and perform its obligations under the Terms and Conditions, and as legal right, title, license and/or authorization to manufacture, commercialise, distribute and/or sell. </li>
    </ul>
    
    <h2>6. Confidentiality:</h2>
    <ul>
        <li>For a period of the term of the present agreement and any renewal thereof, each party may disclose Confidential information and Trade Secrets. Consequently, the parties shall:</li>
        <ul>
            <li>a) receive and hold the Confidential Information in strict confidence.</li>
            <li>b) take such steps as may be reasonably necessary to prevent the disclosure of Confidential Information using not less than the same degree of care that the receiving party uses to prevent the unauthorized use, dissemination or publication of its own most valuable confidential and proprietary information (but with at least the same degree of care used by a reasonably prudent businessperson);</li>
            <li>c)not disclose such Confidential Information to any third party for any purpose whatsoever without (a) the prior written approval from the disclosing party; and (b) the agreement on the part of such third party to be bound by the restrictions on use and non-disclosure set forth in this Agreement; provided, however, that the receiving party may disclose Confidential Information to the receiving party's Representatives, who are bound by the confidentiality and use provisions of this Agreement;</li>
            <li>d) not disclose such Confidential Information to any third party for any purpose whatsoever without (a) the prior written approval from the disclosing party; and (b) the agreement on the part of such third party to be bound by the restrictions on use and non-disclosure set forth in this Agreement; provided, however, that the receiving party may disclose Confidential Information to the receiving party's Representatives, who are bound by the confidentiality and use provisions of this Agreement;</li>
            <li> e) not disclose, or permit any of its Representatives to disclose, without the prior written consent of the disclosing party, to any other person the fact that the Confidential Information has been made available, that discussions or evaluations are taking place concerning the Purpose, or any of the terms, conditions, or other facts with respect thereto;</li>
            <li> f) acknowledge that the Confidential Information is, and will at all times remain, the exclusive property of the disclosing party; and</li>
            <li> g) use the disclosing party's Confidential Information only for the strictly-limited Purpose and for no other purpose whatsoever. Notwithstanding the foregoing provisions, the receiving party is specifically prohibited from (a) using, directly or indirectly, any of the Confidential Information furnished to it hereunder for its own benefit or for the benefit of others, except for the Purpose as set forth above; or (b) creating any improvements, modifications, or derivative or related works or materials which incorporate or utilize, directly or indirectly, any Confidential Information (such improvements, modifications, derivative or related works, if any, receiving party acknowledges and agrees shall be deemed Confidential Information of the disclosing party</li>
            <li>h) All terms and conditions of this Agreement and any materials provided during the term of the Agreement must be kept confidential by the Supplier and the SellNorm, unless the disclosure is required pursuant to the process of law.</li>
            <li>i) The Supplier will not be entitled to use any information provided to him/her even after the conclusion of the Agreement for a period of five (5) years.</li>
            <li>j) Disclosing or using this information for any purpose beyond the scope of this Agreement, or beyond the exceptions set forth above, is expressly forbidden without the prior consent of the SellNorm.</li>
            <li>k) This section will remain in force regardless of the fact in case the Agreement is terminated.</li>
            <li>l) The Supplier is not to use the SellNorm’s information for advertisements or any other promotional activity without the SellNorm’s written consent.</li>
            <li>m) The Supplier is not to use any intellectual property provided to him/her by the SellNorm without the written consent of the SellNorm.</li>
        </ul>
    </ul>
    
    <h2>7. Payment:</h2>
    <ul>
        <li>SellNorm shall create an electronic remittance for the payment of the goods the Supplier. The Supplier shall provide all the necessary corporate and tax information to SellNorm upon signing this Agreement. Payment to the Supplier will be available in the wallet of the SellNorm every month.</li>
        <li>Unless otherwise expressly provided in writing, each Party is responsible for its own costs of complying with this Agreement and in connection with the performance of its obligations stated hereunder.</li>
        <li>Supplier shall provide the absolute cost of goods for each of the items at Manufacturer’s suggested retail price (“MSRP”) shall be defined by the Supplier through onboarding excel sheets and predefined at the time of product/SKU onboarding but be subject to change by SellNorm.</li>
        <li>The Supplier shall provide the pricing and the details of each product in a PRODUCT WELCOME SHEET (PWS).</li>
    </ul>
    
    <h2>8. Technology and Access:</h2>
    <ul>
        <li>Norm Portal Access: SellNorm shall Provide each Supplier with access to the Norm portal, for multiple users.</li>
    </ul>
    
    <h2>9. Monitoring and Reporting:</h2>
    <ul>
        <li>Performance Tracking: SellNorm shall implement a system for the Supplier to verify and track their performance, including the sales generated.</li>
    </ul>
    
    <h2>10. Limitation of Liability:</h2>
    <ul>
        <li>Under no circumstances will either party be liable for any indirect, special, consequential, or punitive damages (including lost profits) arising out of or relating to this Agreement or the transactions it contemplates (whether for breach of contract, tort, negligence, or other form of action) in case such is not related to the direct result of one party’s negligence or breach.</li>
        <li>The Parties each agree to indemnify and hold harmless the other Party, its respective affiliates, officers, Suppliers, employees, and permitted successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result from the negligence of or breach of this Agreement by the indemnifying Party, its respective successors and assigns that occurs in connection with this Agreement. </li>
 <li>	In an event where any provision of this Agreement is found to be void and unenforceable by a court of competent jurisdiction, then the remaining provisions will remain to be enforced in accordance with the Parties’ intention.</li>
<li>The failure by either Party to exercise any right, power or privilege under the terms of this Agreement will not be construed as a waiver of any subsequent or further exercise of that right, power or privilege or the exercise of any other right, power or privilege.</li>
</ul>
 <h2>11. Entire Agreement:</h2>
    <ul>
    <li>This Agreement contains the entire Agreement and understanding among the Parties hereto with respect to the subject matter hereof, and supersedes all prior agreements, understandings, inducements and conditions, express or implied, oral or written, of any nature whatsoever with respect to the subject matter hereof. The express terms hereof control and supersede any course of performance and/or usage of the trade inconsistent with any of the terms hereof.	
    </li>
       <li>SellNorm may amend at its sole discretion this agreement to include additional terms and conditions.	
    </li>
    <li>As such, any amendments made will be applied to this Agreement.</li>
    </ul>

`);

    popup.document.close();
};