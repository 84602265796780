
export default function Footer() {
   return (
      <>
         <footer className="footer">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-sm-2">
                     2024 © SellNorm.
                  </div>
                  <div className="col-sm-6">
                     <a href="#" target="_blank">PRIVACY POLICY</a>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
}
// export default Header;