import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Cookie from "js-cookie";
import { useLoading } from "./context/LoadingContext";
import Header from "./components/Supplierdashbord/ProductHeader";
import UserLayout from "./layouts/UserLayout";
import AdminLayout from "./layouts/AdminLayout";
import { useAuth } from "./context/AuthContext";
import RegisterSupplier from "./components/Suppliers/RegisterSupplier";
import EmailVerification from "./components/Suppliers/SupplierBasicDetail";
import SupplierBasicDetail from "./components/Suppliers/SupplierBasicDetail";

const Login = lazy(() =>
  wait(1000).then(() => import("./components/Login/Login"))
);
const SupplierLogin = lazy(() =>
  wait(1000).then(() => import("./components/Login/SupplierLogin"))
);

const SuspenseLoader = lazy(() =>
  wait(1000).then(() => import("./common/SuspenseLoader"))
);
const Dashboard = lazy(() =>
  wait(1000).then(() => import("./components/Dashboard"))
);
const Permissions = lazy(() =>
  wait(1000).then(() =>
    import("./components/AccessManagement/Permissions/Permissions")
  )
);
const Roles = lazy(() =>
  wait(1000).then(() => import("./components/AccessManagement/Roles/Roles"))
);
const RolesAdd = lazy(() =>
  wait(1000).then(() => import("./components/AccessManagement/Roles/RolesAdd"))
);
const RolesEdit = lazy(() =>
  wait(1000).then(() => import("./components/AccessManagement/Roles/RolesAdd"))
);
const Users = lazy(() =>
  wait(1000).then(() => import("./components/AccessManagement/Users/Users"))
);
const UsersAdd = lazy(() =>
  wait(1000).then(() => import("./components/AccessManagement/Users/UsersAdd"))
);
const Suppliers = lazy(() =>
  wait(1000).then(() => import("./components/Suppliers/Suppliers"))
);
const EditSupplier = lazy(() =>
  wait(1000).then(() => import("./components/Suppliers/EditSupplier"))
);
const Affiliate = lazy(() =>
  wait(1000).then(() => import("./components/Affiliate/Affiliate"))
);
const AddAffiliate = lazy(() =>
  wait(1000).then(() => import("./components/Affiliate/AddAffiliate"))
);
const TicketCategory = lazy(() =>
  wait(1000).then(() => import("./components/TicketCategory/TicketCategory"))
);
const AddEditTicketCategories = lazy(() =>
  wait(1000).then(() =>
    import("./components/TicketCategory/AddEditTicketCategories")
  )
);
const TicketPriority = lazy(() =>
  wait(1000).then(() => import("./components/TicketPriority/TicketPriority"))
);
const AddEditTicketPriorities = lazy(() =>
  wait(1000).then(() =>
    import("./components/TicketPriority/AddEditTicketPriorities")
  )
);
const Marketplace = lazy(() =>
  wait(1000).then(() => import("./components/Marketplace/Marketplace"))
);
const MarketplaceAdd = lazy(() =>
  wait(1000).then(() => import("./components/Marketplace/MarketplaceAdd"))
);
const ShippingCarriers = lazy(() =>
  wait(1000).then(() =>
    import("./components/ShippingCarriers/ShippingCarriers")
  )
);
const ShippingCarriersAdd = lazy(() =>
  wait(1000).then(() =>
    import("./components/ShippingCarriers/ShippingCarriersAdd")
  )
);
const Brands = lazy(() =>
  wait(1000).then(() => import("./components/Brands/Brands"))
);
const Category = lazy(() =>
  wait(1000).then(() => import("./components/Category/Category"))
);
const State = lazy(() =>
  wait(1000).then(() => import("./components/State/State"))
);
const Cities = lazy(() =>
  wait(1000).then(() => import("./components/Cities/Cities"))
);
const Fulfillment = lazy(() =>
  wait(1000).then(() =>
    import("./components/AccessManagement/Fulfillment/User")
  )
);
const FulfillmentAdd = lazy(() =>
  wait(1000).then(() =>
    import("./components/AccessManagement/Fulfillment/UsersAdd")
  )
);
const ShippingServices = lazy(() =>
  wait(1000).then(() =>
    import("./components/ShippingServices/ShippingServices")
  )
);
const ShippingServicesAdd = lazy(() =>
  wait(1000).then(() =>
    import("./components/ShippingServices/ShippingServicesAdd")
  )
);
const Products = lazy(() =>
  wait(1000).then(() => import("./components/Products/Products"))
);
const AddProduct = lazy(() =>
  wait(1000).then(() => import("./components/Products/AddProduct"))
);
const Warehouses = lazy(() =>
  wait(1000).then(() => import("./components/Warehouses/Warehouses"))
);
const Suppliersdashbord = lazy(() =>
  wait(1000).then(() => import("./components/Supplierdashbord/Product"))
);
const Productimage = lazy(() =>
  wait(1000).then(() => import("./components/Supplierdashbord/Productimage"))
);
const Productdetails = lazy(() =>
  wait(1000).then(() => import("./components/Supplierdashbord/Productdetails"))
);
const Warehousess = lazy(() =>
  wait(1000).then(() =>
    import("./components/Supplierdashbord/SupplierWarehouses")
  )
);
const Inventory = lazy(() =>
  wait(1000).then(() => import("./components/Supplierdashbord/Inventory"))
);
const WarehousesAdd = lazy(() =>
  wait(1000).then(() => import("./components/Warehouses/WarehousesAdd"))
);
const ProtectedRoute = ({ element, role }) => {
  const { user, isImpersonating } = useAuth();
  return user?.user_type === role || isImpersonating ? (
    element
  ) : (
    <Navigate to="/login" />
  );
};

function AppRoutes() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        {/* Admin Routes */}
        <Route
          path="/*"
          element={<ProtectedRoute role="admin" element={<AdminLayout />} />}
        >
          <Route path="brands" element={<Brands />} />
          <Route exact path="" element={<Dashboard />} />
          <Route exact path="home" element={<Dashboard />} />
          <Route exact path="permissions" element={<Permissions />} />
          <Route exact path="roles" element={<Roles />} />
          <Route exact path="roles/add" element={<RolesAdd />} />
          <Route exact path="roles/edit/:id" element={<RolesEdit />} />
          <Route exact path="users" element={<Users />} />
          <Route exact path="users/add" element={<UsersAdd />} />
          <Route exact path="users/edit/:id" element={<UsersAdd />} />
          <Route exact path="fulfillment-users" element={<Fulfillment />} />
          <Route
            exact
            path="fulfillment-users/add"
            element={<FulfillmentAdd />}
          />
          <Route
            exact
            path="fulfillment-users/edit/:id"
            element={<FulfillmentAdd />}
          />
          <Route exact path="suppliers/:status" element={<Suppliers />} />
          <Route exact path="suppliers/add" element={<EditSupplier />} />
          <Route exact path="suppliers/edit/:id" element={<EditSupplier />} />
          <Route exact path="affiliate" element={<Affiliate />} />
          <Route exact path="affiliate/add" element={<AddAffiliate />} />
          <Route exact path="affiliate/edit/:id" element={<AddAffiliate />} />
          <Route exact path="ticket-priority" element={<TicketPriority />} />
          <Route
            exact
            path="ticket-priority/add"
            element={<AddEditTicketPriorities />}
          />
          <Route
            exact
            path="ticket-priority/edit/:id"
            element={<AddEditTicketPriorities />}
          />
          <Route exact path="ticket-category" element={<TicketCategory />} />
          <Route
            exact
            path="ticket-category/add"
            element={<AddEditTicketCategories />}
          />
          <Route
            exact
            path="ticket-category/edit/:id"
            element={<AddEditTicketCategories />}
          />
          <Route exact path="marketplace" element={<Marketplace />} />
          <Route exact path="marketplace/add" element={<MarketplaceAdd />} />
          <Route
            exact
            path="marketplace/edit/:id"
            element={<MarketplaceAdd />}
          />
          <Route
            exact
            path="shipping-carriers"
            element={<ShippingCarriers />}
          />
          <Route
            exact
            path="shipping-carriers/add"
            element={<ShippingCarriersAdd />}
          />
          <Route
            exact
            path="shipping-carriers/edit/:id"
            element={<ShippingCarriersAdd />}
          />
          <Route
            exact
            path="shipping-services"
            element={<ShippingServices />}
          />
          <Route
            exact
            path="shipping-services/add"
            element={<ShippingServicesAdd />}
          />
          <Route
            exact
            path="shipping-services/edit/:id"
            element={<ShippingServicesAdd />}
          />
          <Route exact path="products" element={<Products />} />
          <Route exact path="product/add" element={<AddProduct />} />
          <Route exact path="product/edit/:id" element={<AddProduct />} />
          <Route exact path="category" element={<Category />} />
          <Route exact path="state" element={<State />} />
          <Route exact path="Cities" element={<Cities />} />
          <Route exact path="warehouses" element={<Warehouses />} />
          <Route exact path="warehouses/add" element={<WarehousesAdd />} />
          {/* <Route exact path="warehouses" element={<Warehouses />} /> */}
        </Route>
        {/* Supplier Routes */}
        <Route
          path="/supplier/*"
          element={<ProtectedRoute role="supplier" element={<UserLayout />} />}
        >
          <Route exact path="" element={<Suppliersdashbord />} />
          <Route
            exact
            path="suppliersdashboard"
            element={<Suppliersdashbord />}
          />
          <Route exact path="productimage" element={<Productimage />} />
          <Route exact path="productdetails" element={<Productdetails />} />
          <Route exact path="warehouses" element={<Warehousess />} />
          <Route exact path="inventory" element={<Inventory />} />
        </Route>

        {/* Default redirect */}
        <Route path="/login" element={<Login />} />
        <Route path="/supplierlogin" element={<SupplierLogin />} />
        <Route path="/register" element={<RegisterSupplier />} />
        <Route path="/supplierbasicinfo" element={<SupplierBasicDetail />} />
        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
      </Routes>
    </Suspense>
  );
}

function wait(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export default AppRoutes;
