import React from "react";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import apiInstance, { ENDPOINTS } from "../../Api";
import { setLoginToken } from "../../services/storage";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { useLoading } from "../../context/LoadingContext";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
export default function ProductHeader() {
   const { login, setIsImpersonating } = useAuth()
   const { loading, setLoading } = useLoading();

   const navigate = useNavigate();
   const stopImpersonation = async () => {
      setLoading(true)
      try {
         const response = await apiInstance.post(ENDPOINTS.stopImpersonation, { admin_id: JSON.parse(localStorage.getItem('admin_id')) });
         if (response.data.success) {
            let authToken = response.data?.data?.token
            // Assuming response contains an auth token and user info
            localStorage.setItem('user_type', JSON.stringify(response.data?.data.user_type));
            login({ user_type: response.data?.data?.user_type });
            localStorage.removeItem('supplier_id');
            setIsImpersonating(false);
            setLoginToken(authToken)
            navigate("/")
            setLoading(false);
            toast.success("Login successful!");

         } else {
            toast.error(response.data.message);
         }
         setLoading(false);
      } catch (error) {
         toast.error('Error Login as Suppliers');
      }
   };
   return (
      <>
         <header id="page-topbar" className="">
            <div className="layout-width">
               <div className="navbar-header">
                  <div className="d-flex">
                     {/* <!-- LOGO --> */}
                     <div className="navbar-brand-box horizontal-logo">
                        <a href="#" className="logo logo-dark">
                           <span className="logo-sm">
                              <img src="/static/media/logo.0bdc3bf222e1bd525ff9.jpg" alt="" height="36" />
                           </span>
                           <span className="logo-lg">
                              <img src="/static/media/logo.0bdc3bf222e1bd525ff9.jpg" alt="" height="36" />
                           </span>
                        </a>
                        <a href="#" className="logo logo-light">
                           <span className="logo-sm">
                              <img src="/static/media/logo.0bdc3bf222e1bd525ff9.jpg" alt="" height="36" />
                           </span>
                           <span className="logo-lg">
                              <img src="/static/media/logo.0bdc3bf222e1bd525ff9.jpg" alt="" height="36" />
                           </span>
                        </a>
                     </div>

                     <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
                        <span className="hamburger-icon">
                           <span></span>
                           <span></span>
                           <span></span>
                        </span>
                     </button>
                  </div>
                  <div className="d-flex align-items-center">


                     <div className="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <span className="d-flex align-items-center">
                              <img src="/static/media/logo.0bdc3bf222e1bd525ff9.jpg" alt="" height="36" />
                              <span className="text-start ms-xl-2">
                                 <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                    {JSON.parse(localStorage.getItem('name'))}
                                 </span>
                                 <div className="profile-text css-1y7ty71" style={{ fontSize: "14px" }}>
                                    <span className="css-bv69nn">{JSON.parse(localStorage.getItem('user_type'))}</span>
                                 </div>
                              </span>
                           </span>
                        </button>
                        <Tooltip title="Stop-Impersonation">  <StopCircleIcon onClick={stopImpersonation} />
                        </Tooltip>
                        {/* <div className="dropdown-menu dropdown-menu-end">
                        
                           <h6 className="dropdown-header">Welcome Camilla!</h6>
                           <a className="dropdown-item" href="#"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Profile</span></a>
                           <a className="dropdown-item" href="#" onclick="event.preventDefault();
                              document.getElementById(&#39;logout-form&#39;).submit();">
                                <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                                <span className="align-middle" data-key="t-logout">Logout</span>
                            </a>
                           <form id="logout-form" action="#" method="POST" className="d-none">
                              <input type="hidden" name="_token" value="" />                        
                           </form>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </header>
         <div className="app-menu navbar-menu">

            <div id="scrollbar">
               <div className="container-fluid">
                  <div id="two-column-menu">
                  </div>
                  <ul className="navbar-nav" id="navbar-nav">
                     <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                     <li className="nav-item">
                        <a className="nav-link " href="#">
                           <i className="fa fa-home"></i>
                           <span data-key="t-dashboards">Home</span>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link  supplier-active-menu bg-light text-dark " href="#">
                           <i className="fa fa-dropbox"></i>
                           <span data-key="t-dashboards" data-toggle="tooltip" data-placement="top">Products</span>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " href="#">
                           <i className="fa fa-dropbox"></i>
                           <span data-key="t-dashboards" data-toggle="tooltip" data-placement="top">Retailers</span>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " href="#">
                           <i className="fa fa-dropbox"></i>
                           <span data-key="t-dashboards" data-toggle="tooltip" data-placement="top">Finances</span>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " href="#">
                           <i className="fa fa-dropbox"></i>
                           <span data-key="t-dashboards" data-toggle="tooltip" data-placement="top">Support</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="sidebar-background"></div>
         </div>

      </>
   );
}
// export default Header;