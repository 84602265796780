import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiInstance, { ENDPOINTS } from "../../Api";
import { toast } from "react-toastify";
import {
    TextField,
    Typography, Box, InputAdornment,
    Button
} from "@mui/material";
import { useLoading } from "../../context/LoadingContext";
import { openTermsInPopup } from "../../constant/constant";
function SupplierBasicDetail() {
    const { loading, setLoading } = useLoading();
    const navigate = useNavigate();
    const [addUser, setAddUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        website_url: "",
        commercial_company_name: "",
        address: "",
        status: "pending",
        currency: "",
        bank_details: ""

    });
    const [otpValue, setOtpValue] = useState({
        value: null,
        emailVerifyStatus: true,
        otpVerifyStatus: false,
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddUser({
            ...addUser,
            [name]: value,
        });
    };
    useEffect(() => {
        document.title = "SellNorm | UsersAdd";
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await apiInstance.post(
                ENDPOINTS.register,
                addUser
            );
            if (response.data.success) {
                toast.success(response.data.message);
                navigate("/login");
            } else {
                toast.error(response.data.message);
            }
            setLoading(false);

        } catch (error) {

            setLoading(false);
            toast.error("Error Add Suppliers");
        }

    };
    const handleSentOtp = async () => {
        try {
            const response = await apiInstance.post(
                ENDPOINTS.emailverificationCode,
                { email: addUser?.email }
            );
            if (response.data.success) {
                setOtpValue({ ...otpValue, emailVerifyStatus: true })
                toast.success(response.data.message);

            } else {
                toast.error(response.data.message);
            }
            setLoading(false);

        } catch (error) {

            setLoading(false);
            toast.error("Error Sent otp");
        }
    }
    const handleOtp = async () => {
        try {
            const response = await apiInstance.post(
                ENDPOINTS.codeVerify,
                { email: addUser?.email, code: otpValue.value }
            );
            if (response.data.success) {
                setOtpValue({ ...otpValue, otpVerifyStatus: true })
                toast.success(response.data.message);

            } else {
                toast.error(response.data.message);
            }
            setLoading(false);

        } catch (error) {

            setLoading(false);
            toast.error("Error in verify OTP");
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-8" style={{ margin: "0 auto" }}>
                    <section className="ant-layout ant-layout-has-sider css-1i5zyo0" id="supplierresgiter">
                        <section
                            className="ant-layout css-1i5zyo0">
                            <div className="css-14vsxfy mt-0 pt-4">
                                <form onSubmit={handleSubmit}>
                                    <main className="ant-layout-content h-100 css-1i5zyo0">
                                        <div
                                            id="advanced_search"
                                            className="ant-form ant-form-vertical css-1i5zyo0 ant-advanced-search-form"
                                        >
                                            <div className="container">
                                                <div
                                                    className="ant-tabs ant-tabs-top css-1i5zyo0"
                                                    style={{ marginTop: "30px" }}
                                                >
                                                    <div className="ant-tabs-content-holder">
                                                        <div className="ant-tabs-content ant-tabs-content-top">
                                                            <div
                                                                role="tabpanel"
                                                                tabIndex="0"
                                                                aria-hidden="false"
                                                                className="ant-tabs-tabpane ant-tabs-tabpane-active"
                                                                id="rc-tabs-0-panel-1"
                                                                aria-labelledby="rc-tabs-0-tab-1"
                                                            >
                                                                <div
                                                                    className="ant-row css-1i5zyo0"
                                                                    style={{
                                                                        marginLeft: "-8px",
                                                                        marginRight: "-8px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col-12 css-1i5zyo0"
                                                                        style={{
                                                                            paddingLeft: "8px",
                                                                            paddingRight: "8px",
                                                                        }}
                                                                    >
                                                                        <Typography mb={2} variant="h5" gutterBottom>
                                                                            Supplier Information
                                                                        </Typography>
                                                                        <div className="ant-card ant-card-bordered css-1i5zyo0">
                                                                            <form>
                                                                                <div className="ant-card-body">
                                                                                    <div className="form-row ant-form-item css-1i5zyo0">

                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="Name"
                                                                                            >
                                                                                                Business Name
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="commercial_company_name"
                                                                                                value={addUser.commercial_company_name}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Business Name"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="Name"
                                                                                            >
                                                                                                Website
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="website_url"
                                                                                                value={addUser.website_url}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Website"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>

                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="Name"
                                                                                            >
                                                                                                First Name
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="first_name"
                                                                                                value={addUser.first_name}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="First Name"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="Name"
                                                                                            >
                                                                                                Last Name
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="last_name"
                                                                                                value={addUser.last_name}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Last Name"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            {/* <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="Email"
                                                                                            >
                                                                                                Email
                                                                                            </label> */}
                                                                                            {/* <input
                                                                                                type="email"
                                                                                                name="email"
                                                                                                value={addUser.email}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Email"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            /> */}
                                                                                            <TextField
                                                                                                label="Email"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                                type={'email'}
                                                                                                value={addUser?.email}
                                                                                                onChange={e => {
                                                                                                    setAddUser({ ...addUser, email: e.target.value })
                                                                                                }}
                                                                                                InputProps={{
                                                                                                    endAdornment: (
                                                                                                        <InputAdornment position="end">
                                                                                                            <Button
                                                                                                                sx={{
                                                                                                                    margin: '0px',
                                                                                                                    backgroundColor: '#2E3591',
                                                                                                                    boxShadow: 'none',
                                                                                                                }}
                                                                                                                variant="contained"
                                                                                                                onClick={handleSentOtp}
                                                                                                            >
                                                                                                                Send Otp
                                                                                                            </Button>
                                                                                                        </InputAdornment>
                                                                                                    ),
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            {otpValue.emailVerifyStatus && (
                                                                                                <TextField
                                                                                                    className="register_input_fields"
                                                                                                    label="Otp"
                                                                                                    type={'number'}
                                                                                                    value={otpValue?.value}
                                                                                                    onChange={e => {
                                                                                                        setOtpValue({
                                                                                                            ...otpValue,
                                                                                                            value: parseInt(e.target.value),
                                                                                                        })
                                                                                                    }}
                                                                                                    InputProps={{
                                                                                                        endAdornment: (
                                                                                                            <InputAdornment position="end">
                                                                                                                <Button
                                                                                                                    sx={{
                                                                                                                        margin: '0px',
                                                                                                                        backgroundColor: '#2E3591',
                                                                                                                        boxShadow: 'none',
                                                                                                                    }}
                                                                                                                    variant="contained"
                                                                                                                    onClick={handleOtp}
                                                                                                                >
                                                                                                                    Verify
                                                                                                                </Button>
                                                                                                            </InputAdornment>
                                                                                                        ),
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="Phone Number"
                                                                                            >
                                                                                                Phone Number
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                name="phone_number"
                                                                                                value={addUser.phone_number}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Phone Number"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="address"
                                                                                            >
                                                                                                Business address
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="address"
                                                                                                value={addUser.address}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Business address"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="currency"
                                                                                            >
                                                                                                Currency
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="currency"
                                                                                                value={addUser.currency}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Currency"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group col-md-6 ant-form-item-label css-1i5zyo0">
                                                                                            <label
                                                                                                htmlFor="advanced_search_name"
                                                                                                className="ant-form-item-required"
                                                                                                title="bank_details"
                                                                                            >
                                                                                                Bank Details
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                name="bank_details"
                                                                                                value={addUser.bank_details}
                                                                                                onChange={handleInputChange}
                                                                                                placeholder="Bank Details"
                                                                                                aria-required="true"
                                                                                                className="ant-input css-1i5zyo0"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group col-md-12 ant-form-item-label css-1i5zyo0">
                                                                                        <Button disabled={otpValue.otpVerifyStatus ? false : true} variant="contained" color="primary" onClick={handleSubmit} >
                                                                                            Submit
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </form>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </>
    );
}

export default SupplierBasicDetail;