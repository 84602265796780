// src/layouts/UserLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Supplierdashbord/Footer';
import ProductHeader from '../components/Supplierdashbord/ProductHeader';
import { useLoading } from "../context/LoadingContext";
import Loader from '../common/Loader';

const UserLayout = () => {
    const { loading, setLoading } = useLoading();
    return (
        <div>
            <ProductHeader />
            <Footer />
            {loading && <Loader />}
            <Outlet />
        </div>
    );
};
export default UserLayout;
