
import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader'; // Assuming Loader is imported
import { Outlet } from 'react-router-dom';
import { useLoading } from "../context/LoadingContext";

const AdminLayout = () => {
  const { loading, setLoading } = useLoading();
  return (
    <div>
      <Header />
      <Sidebar />
      {loading && <Loader />}
      <Outlet />
    </div>
  );
};

export default AdminLayout;
