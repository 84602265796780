import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
    return (
        <>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: '#3e79f7' }} />
            </div>
        </>
    )
}